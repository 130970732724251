.custom-table .p-datatable-tbody tr td {
  padding: 0rem 0.25rem;
}

.report-table .p-datatable-tbody tr td {
  padding: 0.75rem 0.25rem;
}

.calendar-report {
  border: 1px solid #8f8e8e;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: white;
  padding-left: 12px;
  height: 35px;
  box-shadow: 0 0 0 #97b3e0;
  transition: box-shadow 0.3s ease; /* Smooth transition effect */

  /* Add box-shadow on click (focus within the container) */
  &:focus-within {
    box-shadow: 0 0 4px 2px #3b6bb8;
  }
  span {
    input {
      border: none;
      border-radius: 0;
      padding: 0 4px;
      color: black;
      cursor: pointer;
      font-weight: 500;
      box-shadow: none;
      width: 268px;
    }
  }
  button {
    background-color: black;
  }
}

.calendar-container {
  border: 1px solid #8f8e8e;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: white;
  padding-left: 12px;
  height: 35px;
  box-shadow: 0 0 0 #97b3e0;
  transition: box-shadow 0.3s ease; /* Smooth transition effect */

  /* Add box-shadow on click (focus within the container) */
  &:focus-within {
    box-shadow: 0 0 4px 2px #3b6bb8;
  }
  span {
    input {
      border: none;
      border-radius: 0;
      padding: 0 4px;
      color: black;
      cursor: pointer;
      font-weight: 500;
      box-shadow: none;
    }
  }
  button {
    background-color: black;
  }
}

.search-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  width: 100%;
}

.search-item {
  min-width: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .search-container {
    grid-template-columns: repeat(2, 1fr);
  }
  .searchEmployee {
    display: flex !important;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
  }
}

.search-item .p-dropdown,
.search-item .p-inputtext {
  width: 100%;
  box-sizing: border-box;
}

.searchEmployee {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
}

.week-grid {
  padding-top: 4px;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(10, 1fr); /* 10 columns */
}

.month-grid {
  padding-top: 4px;

  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(6, 1fr);
}

.calendar-custom {
  width: 100%;
}

.btn-custom button {
  padding: 4px 8px;
}

#initial_debt input,
#payment_value input {
  width: 100%;
}
@media (max-width: 640px) {
  .week-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(5, 1fr); /* 10 columns */
  }
  .month-grid {
    display: grid !important;
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);
  }
  .calendar-custom {
    width: 90% !important;
  }
}

@media (max-width: 600px) {
  .login-container {
    min-width: 350px !important;
  }
}

.custom-row {
  cursor: pointer;
}

.active-row {
  background-color: #295bac;
  color: white;
}
// .custom-button {
//   color: white;
// }

/* Default button style */
.custom-button {
  color: #000; /* Black color */
}

/* Active button style */
.active-button {
  background-color: #295bac;
  color: white;
}

@media (max-width: 1200px) {
  .toggle-button {
    display: none;
  }
  .logo-image {
    display: none !important;
  }
  .p-sidebar .p-sidebar-content {
    overflow: hidden;
  }
}

.p-sidebar-header {
  display: flex;
  justify-content: center;
}

.sidebar {
  position: relative;
  height: 100%;
  // overflow: hidden;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: column;
}

.sidebar-open {
  width: 18rem;
}
.sidebar-closed {
  width: 110px;
  align-items: center;
}

.menu-item-icon {
  display: block;
}

.menu-item-text {
  display: block;
}

.sidebar-closed .menu-item-text {
  display: none;
}

.menu-item-icon-only .p-menuitem-content {
  justify-content: center;
}

.menu-container {
  transition: opacity 0.3s ease;
}

.logo-image {
  display: flex;
}

.toggle-button {
  position: absolute;
  left: 76%;
  transform: translate(40%, 50%);
  background-color: #e7e7e7;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-open {
  left: 90%;
}

@media (max-width: 1200px) {
  .toggle-button {
    display: none;
  }
  .logo-image {
    display: none !important;
  }
  .p-sidebar .p-sidebar-content {
    overflow: hidden;
  }
}

.p-sidebar-icons button {
  position: absolute;
  right: 1rem;
}

.btn {
  background-color: #e7e7e7;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.custom-table .p-datatable-thead {
  height: 3rem;
}

.custom-table .p-datatable-tbody tr {
  height: 3rem;
  // max-height: 465px;
}

.custom-table .p-datatable-tbody > tr:hover {
  background-color: #f0f0f0;
}

.no-hover-effect .p-datatable-thead {
  height: 3rem;
}

.no-hover-effect .p-datatable-tbody tr {
  height: 3rem;
  // max-height: 465px;
}

.package-table .p-datatable-thead {
  height: 3rem;
}

.package-table .p-datatable-tbody tr {
  height: 3rem;
  max-height: 465px;
}

.package-table .p-datatable-tbody > tr:hover {
  background-color: #f0f0f0;
}

.upload-file {
  display: inline-block;
  background-color: #3b6bb8;
  color: white;
  padding: 8px 22px;
  cursor: pointer;
  margin: 4px 2px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
}

.p-datatable-header {
  display: flex;
  justify-content: end;
}

.select-custom .multiSeclect-custom {
  background-color: white;
  border: none;
  color: black;
}
.select-custom .p-multiselect-label {
  display: none;
}

.select-custom .p-multiselect-trigger {
  transform: scale(1.5);
}

.select-custom .p-multiselect {
  box-shadow: none;
}

.select-custom .p-multiselect .p-multiselect-panel {
  box-shadow: none;
}

.select-custom .p-multiselect .p-multiselect-trigger {
  box-shadow: none;
}

.select-custom .pi-cog:before {
  color: black;
}

/* Tùy chỉnh thanh tiến trình */
.custom-progress {
  -webkit-appearance: none; /* Xóa kiểu mặc định của thanh tiến trình trên Webkit browsers */
  appearance: none;
  width: 100%;
  height: 12px; /* Thay đổi chiều cao theo ý muốn */
  border-radius: 10px; /* Góc bo tròn */
}

/* Tùy chỉnh thanh tiến trình cho Webkit browsers (Chrome, Safari) */
.custom-progress::-webkit-progress-bar {
  background-color: #d1d5db; /* Màu nền của thanh tiến trình */
  border-radius: 10px;
}

.custom-progress::-webkit-progress-value {
  background-color: #295bac; /* Màu của thanh tiến trình khi đang tiến triển */
  border-radius: 5px;
}

/* Tùy chỉnh thanh tiến trình cho Firefox */
.custom-progress::-moz-progress-bar {
  background-color: #295bac; /* Màu của thanh tiến trình khi đang tiến triển */
  border-radius: 5px;
}

.p-timeline-event-connector {
  background-color: #295bac;
}

.dot {
  content: "";
  height: 12px;
  width: 12px;
  background-color: black;
  border-radius: 50%;
}

.p-button-group .p-button:first-of-type,
.p-button-group .p-button:last-of-type {
  height: 35px;
}

// thông báo
.custom-button {
  background-color: #e2e5e9;
  color: #333;
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #d0d3d7;
}

.custom-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(215, 216, 218, 0.5);
}

.notification-item {
  display: flex;
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.notification-item:hover {
  background-color: #f0f0f0;
}

.seen {
  background-color: #dee5f1;
  color: rgb(29, 27, 27);
}

.unseen {
  background-color: transparent;
}

@media (max-width: 768px), (max-width: 1200px) {
  .hide-on-small-screen {
    display: none;
  }
  .ishow {
    width: 100%;
  }
}

.custom-icon {
  color: red; /* Thay đổi màu icon thành đỏ */
}

.p-overlaypanel:before {
  display: none;
}

/* Override the default header background color */
.nested-table .p-datatable-thead > tr > th {
  background-color: transparent !important; /* Remove header background */
  border: none; /* Remove header border */
  color: inherit; /* Keep the default text color, adjust if needed */
}

.gray-row {
  background-color: #f7f7f7; /* Light gray color */
}
