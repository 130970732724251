$primaryColor: #295bac !default; // màu ban đầu
$primaryLightColor: #97b3e0 !default; //màu viền
$primaryDarkColor: #284b83 !default; // màu hover
$primaryDarkerColor: #284b83 !default; // màu click
$primaryTextColor: #ffffff !default; // màu chữ

$highlightBg: #f0fdfa !default;
$highlightTextColor: $primaryDarkerColor !default;
$highlightFocusBg: rgba($primaryColor, 0.24) !default;

@import "../_variables";
@import "./_fonts";
@import "../../../theme-base/_components";
@import "../_extensions";
